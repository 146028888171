<script setup lang="ts">
import dayjs from "dayjs";

import type { Promotion } from "@/types";

const { t } = useT();

const props = defineProps<{
	type?: Promotion["type"];
	image: string;
	title: string;
	entries: number;
	end: string;
	start: string;
	isAvailable: boolean;
}>();

const startDate = dayjs(props.start).format("D MMM");
const endDate = dayjs(props.end).format("D MMM");
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 90,
			height: 90,
			src: image
		}"
		width="330px"
		height="310px"
	>
		<template #top>
			<AText variant="ternopil" class="label-time" :modifiers="['uppercase']">
				<b>{{ startDate }} - {{ endDate }}</b>
			</AText>
			<AText class="title" :modifiers="['semibold', 'uppercase']">
				{{ title }}
			</AText>
		</template>
		<template #bottom>
			<div class="box">
				<AText v-if="entries" variant="tunis" :modifiers="['bold', 'uppercase']" class="count">
					<ASvg name="12/secret-coins" class="icon" />
					{{ numberFormat(entries) }}
				</AText>
				<AText class="info-txt" as="div" variant="ternopil" :modifiers="['center', 'uppercase']">
					<i18n-t keypath="select offer and get {key} to start the challenge">
						<template #key>
							<b>{{ t("winter challenge ticket") }}</b>
						</template>
					</i18n-t>
				</AText>
				<AButton v-if="!isAvailable" variant="primary" class="promo" data-tid="promo-get-ticket">
					<AText :modifiers="['bold', 'uppercase']">
						{{ t("Get ticket") }}
					</AText>
					<NuxtIcon name="20/ticket" class="icon-ticket" filled />
				</AButton>
				<AButton v-else variant="primary" class="promo" data-tid="promo-explore-challanger">
					<AText :modifiers="['bold', 'uppercase']">
						{{ t("Explore challenges") }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	color: var(--coro);
	background: url("/nuxt-img/challenge/card-bg.png") center/cover no-repeat;

	.title {
		padding: gutter(0.25) 0;
	}
}

.label-time {
	display: inline-flex;
	gap: gutter(0.5);
	position: absolute;
	top: 0;
	margin: auto;
	border-radius: 0 0 10px 10px;
	background: var(--cagli);
	padding: gutter(0.37) gutter(1.87) gutter(0.25);
	color: var(--coro);
}

.box {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	gap: gutter(1);

	button {
		width: 100%;
		height: 40px;
	}
}

.info-txt {
	margin: auto auto gutter(1);
	padding: 0 gutter(3);
}

.label-time {
	display: inline-flex;
	gap: gutter(0.5);
	position: absolute;
	top: 0;
	margin: auto;
	border-radius: 0 0 10px 10px;
	background: var(--cagli);
	padding: gutter(0.37) gutter(1.87) gutter(0.25);
	color: var(--coro);
}

.count {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: gutter(0.5);

	&.single {
		margin-bottom: gutter(5.5);
	}

	.icon {
		transform: translateY(-1px);
		font-size: 24px;
	}
}

.icon-ticket {
	font-size: 24px;
	margin-left: gutter(1);
}
</style>
